import "./NavBar-Btn.styles.scss";
import {Link} from "react-router-dom";

const Navbarbtn = ({ path, children }) => {
  return (
    <Link
      className='Navbarbtn-Container'
      to={"/"+path}
    >
      {children}
    </Link>
  );
};

export default Navbarbtn;
