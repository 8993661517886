import { Fragment } from "react";
import Navbarbtn from "../Navbar-Btn/NavBar-Btn.component";
import "./navbar.styles.scss";
import {Link, Outlet} from "react-router-dom";
const loginIcon = require("../../Images/navbar/loginIcon.png");
const Navbar = () => {
  return (
    <Fragment>
        <Fragment>
          <div className='Navbar-Container'>
            <Link className='Navbar-Logo' to={"/"}>
              <div className='Navbar-Logo-Container'/>
              <p>travacot</p>
            </Link>
            <div className='Navbar-Buttons-Container'>
              <Navbarbtn path={"Program"}>Program</Navbarbtn>
              <Navbarbtn path={"Contact"}>Contact</Navbarbtn>
              <Navbarbtn path={"Partnership"}>Partnership</Navbarbtn>
              <Navbarbtn path={"Aboutus"}>About us</Navbarbtn>
              <Navbarbtn path={"Blog"}>Blog</Navbarbtn>
              <Navbarbtn path={"Login"}>
                <img src={loginIcon} alt={"Login Icon"}/>
              </Navbarbtn>
            </div>
          </div>
          <div className='Navbar-LowerBorder'/>
        </Fragment>
        <Outlet/>
    </Fragment>
  );
};

export default Navbar;
