import "./loadingPage.styles.scss";
import {Image, ProgressBar} from "react-bootstrap";
import {useEffect, useState} from "react";
const loadingLogo = require("../../Images/Loading/LoadingLogo.png")
const LoadingPage = ({onloadDone}) => {
    const [count , setCount] = useState(0);
    
    useEffect(()=>{
        const interval = setInterval(()=>{
            setCount(count+1);
            if(count === 100){
                clearInterval(interval);
               setTimeout(() =>{
                     onloadDone();
                
               },1800)
            }
        },10);
        return () => {
            clearInterval(interval);
        }
    },[count]);
    
    return (
    <div className="LoadingPage-Container">
        <div>
            <div className="LoadingPage-Container-Logo">
                <Image src={loadingLogo} alt={"Logo"}/> 
            </div>
            <ProgressBar style={{width:"50vw"}} animated={true} now={count} min={0} max={100}/>
        </div>    
    </div>
  );
}

export default LoadingPage;