import "./Profitability-Section.styles.scss"
import ProfitabilityBar from "../Profitability-Bar/Profitability-Bar.component";
import {Image} from "react-bootstrap";

const ProfitabilitySection = ({title,logo,margin=0,width=50,height=50,bars =[{title:"",value:0,text:""}]}) => {
    return(
        <div className="ProfitabilitySection">
            <div className="ProfitabilitySection-Title">
                <Image style={{marginTop:margin+"%"}} width={width} height={height} src={logo} ></Image>
                <p>{title}</p>
            </div>
            <div className="ProfitabilitySection-Bars">
                {
                    bars.map((bar,index) => {
                        return(
                            <ProfitabilityBar key={index} title={bar.title} percentage={bar.value} text={bar.text}/>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ProfitabilitySection;