import "./program.styles.scss";
import ProgramSection from "../../components/ProgramSection/programSection.component";
import {Fragment, useRef} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import ProfitabilitySection from "../../components/Profitabilty-Section/Profitability-Section.component";
const profitLogoOne = require("../../Images/Program/dahherniLogoProfit.png");
const profitLogoTwo = require("../../Images/Program/CompetitorsLogoProfit.png");

const Program = () => {
  const SectionOne = useRef();
  const SectionTwo = useRef();
  const SectionThree = useRef();
  const SectionFour = useRef();
  const SectionFive = useRef();
  const SectionSix = useRef();

  const scrollTo = (section) => {
    section.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
      <Fragment>
        <div className='Program-Container'>
          <div ref={SectionOne}>
            <ProgramSection
              scrollTo={scrollTo}
              goToSection={SectionTwo}
              IsFirst={true}
            >
              <div className='Program-CardContainer'>
                <div className='ProgramSectionOne-Background'>
                  <div className="ProgramSectionOne-BackgroundImage">
                    <div className="ProgramSectionOne-Content">
                      <p className="ProgramSectionOne-Content-Text">Booking a reservation has never been easier!</p>
                    </div>
                  </div>
                </div>
              </div>
            </ProgramSection>
          </div>
          <div ref={SectionTwo}>
            <ProgramSection
              scrollTo={scrollTo}
              goToSection={SectionThree}
            >
             <div className="ProgramSectionTwo-Background">
               <div className="ProgramSectionTwo-Content">
                 <div className="ProgramSectionTwo-Content-Icon"/>
                 <div className="ProgramSectionTwo-Content-Message">
                   <h1>We encourage sustainability!</h1>
                   <p>We try our best to ensure your company is sustainable in the long run!</p>
                 </div>
               </div>
             </div> 
            </ProgramSection>
          </div>
          <div
              ref={SectionThree}
          >
            <ProgramSection
                scrollTo={scrollTo}
                goToSection={SectionFour}
            >
              <div className="ProgramSectionThree">
                <Container>
                  <Row className="justify-content-md-center">
                    <Col md="auto">
                      <p className="ProgramSectionThree-Title">
                        We create for you, because of you!
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="5">
                      <Card className="ProgramSectionThree-Cards">
                        <Card.Img className="ProgramSectionThree-Cards-Images" variant="top" src={require("../../Images/Program/RevConnect.png")}/>
                        <Card.Body>
                          <Card.Text className="ProgramSectionThree-Cards-Text">
                            Our comprehensive business model optimizes earnings by leveraging your contributions.
                            As you drive traffic to our platform, your revenue potential increases proportionately,
                            ensuring mutual growth and success. Our focus remains on your empowerment and prosperity.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={{span:2,offset:2}} lg="5">
                      <Card className="ProgramSectionThree-Cards">
                        <Card.Img className="ProgramSectionThree-Cards-Images" variant="top" src={require("../../Images/Program/Eagle Eye.png")}/>
                        <Card.Body>
                          <Card.Text className="ProgramSectionThree-Cards-Text">
                            EagleEye serves as your indispensable ally in navigating
                            the search for the perfect product in the market of any
                            country of your liking. Empowering you with the ability to
                            discern nuances and analyse products comprehensively, it 
                            assists you in determining whether a product aligns with 
                            your research and requirements,
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="5">
                      <Card className="ProgramSectionThree-Cards">
                        <Card.Img className="ProgramSectionThree-Cards-Images" variant="top" src={require("../../Images/Program/Travalytics.png")}/>
                        <Card.Body>
                          <Card.Text className="ProgramSectionThree-Cards-Text">
                            Harness the power of data to inform strategic decisions,
                            enabling informed choices about the viability of opening a
                            franchise in specific locations. Data-driven insights
                            become your invaluable ally, guiding you towards optimal 
                            outcomes.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={{span:2,offset:2}} lg="5">
                      <Card className="ProgramSectionThree-Cards">
                        <Card.Img className="ProgramSectionThree-Cards-Images" variant="top" src={require("../../Images/Program/WeCare.png")}/>
                        <Card.Body>
                          <Card.Text className="ProgramSectionThree-Cards-Text">
                            Our unconditional love for animals inspires us to establish 
                            category devoted to supporting animal welfare initiatives. 
                            We take great pride in this commitment, as it allows us to
                            contribute directly to the well-being of animals. Through
                            this dedicated effort, we aim to embody compassion and 
                            responsibility, creating a brighter future for our beloved
                            animal companions.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            </ProgramSection>
            <div
                ref={SectionFour}
            >
              <ProgramSection
                  scrollTo={scrollTo}
                  goToSection={SectionFive}
              >
                <div className="ProgramSectionFour">
                  <div className="ProgramSectionFour-Content">
                    <div className="ProgramSectionFour-Content-Title">
                      <div className="ProgramSectionFour-Content-Title-Meter"/>
                      <p>Profitability meter</p>
                    </div>
                    <div className="ProgramSectionFour-Content-Profitability">
                      <ProfitabilitySection 
                          title={"dahherni"} 
                          logo={profitLogoOne} 
                          width={50}
                          height={64}
                          bars={[{title:"Profitability in the Short-term",value: 45},{title: "Profitability in the Long-term",value: 100,text:"+"}]}/>
                      <ProfitabilitySection
                          title={"Competitors (Current OTAs,TAs,IRSs)"}
                          logo={profitLogoTwo}
                          margin={1.4}
                          width={50}
                          height={32}
                          bars={[{title:"Profitability in the Short-term",value: 55},{title: "Profitability in the Long-term",value: 55}]}/>
                    </div>
                  </div>
                </div>
              </ProgramSection>
            </div>
            <div ref={SectionFive}>
              <ProgramSection
              scrollTo={scrollTo}
              goToSection={SectionSix}
              >
                <div className="ProgramSectionFive">
                    <h1>You are connected to</h1>
                    <div className="ProgramSectionFive-Companies">
                      <div className="ProgramSectionFive-Companies-Avis"/>
                      <div className="ProgramSectionFive-Companies-Sixt"/>
                      <div className="ProgramSectionFive-Companies-Hertz"/>
                      <div className="ProgramSectionFive-Companies-Alamo"/>
                      <div className="ProgramSectionFive-Companies-Enterp"/>
                      <div className="ProgramSectionFive-Companies-Budget"/>
                    </div>
                    <p>and more...</p>
                </div>
    
              </ProgramSection>
            </div>
            <div
                ref={SectionSix}
            >
              <ProgramSection
                  IsLast={true}
              >
                <div className="ProgramSectionSix">
                  <div className="ProgramSectionSix-Chidler"/>
                </div>
              </ProgramSection>
            </div>
          </div>
        </div>
      </Fragment>
  );
};

export default Program;
